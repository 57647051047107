import React from 'react';
import PropTypes from 'prop-types';

import { CardBody, Link, TextContainer, Image, ImageContainer, SubTextContainer } from './Card.styled';

const Card = props => {
  const { cardInfo } = props;
  const { title, paragraph, image, link } = cardInfo;
  return (
    <Link href={link.text}>
      <CardBody>
        <TextContainer>
          <h3>{title.text}</h3>
          <ImageContainer>
            <Image src={image.url} style={{ maxWidth: '250px', maxHeight: '250px', width: 'auto', height: 'auto' }} />
          </ImageContainer>
        </TextContainer>
        <SubTextContainer>{paragraph.text}</SubTextContainer>
      </CardBody>
    </Link>
  );
};

Card.propTypes = {
  cardInfo: PropTypes.shape({
    title: PropTypes.shape({
      text: PropTypes.string,
    }),
    paragraph: PropTypes.shape({
      text: PropTypes.string,
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
      dimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
    link: PropTypes.shape({
      text: PropTypes.string,
    }),
  }).isRequired,
};

export default Card;
