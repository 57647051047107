import styled from 'styled-components';

import { COLORS } from '../../constants.styles';

export const ArrowContainerForward = styled.div`
  width: 50px;
  right: -50px;
`;

export const ArrowContainerBackward = styled.div`
  width: 50px;
  left: -50px;
`;

export const ArrowForward = styled.span`
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
`;

export const ArrowBack = styled.span`
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
`;

export const Container = styled.div`
  margin-top: 60px;
`;

export const Image = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center center;
  height: 300px;
  width: 100%;
  border-radius: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextContainer = styled.div`
  margin: 30px;

  @media (max-width: 700px) {
    margin: 5px;
  }
`;

export const SlideHeading = styled.h2`
  color: black;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
`;

export const SlideDescription = styled.div`
  color: ${COLORS.DARK_GREY};
  margin-bottom: 20px;
`;
