import React from 'react';
import { get } from 'lodash';

import NewsSlide from './NewsSlide';
import { Container, ArrowContainerBackward, ArrowContainerForward, ArrowBack, ArrowForward } from './NewsSlide.styled';

const NewsCarousel = ({ news }) => (
  <Container className="container">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {news.map((news, i) => (
          <NewsSlide
            key={news.id}
            active={!i}
            header={get(news, 'title.text', '')}
            descriptionHTML={get(news, 'description.html', '')}
            link={get(news, 'article_url.url', '')}
            image={get(news, 'image.url', '')}
          />
        ))}
      </div>
      <ArrowContainerBackward
        className="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <ArrowBack className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </ArrowContainerBackward>
      <ArrowContainerForward
        className="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <ArrowForward className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </ArrowContainerForward>
    </div>
  </Container>
);

export default NewsCarousel;
