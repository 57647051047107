import styled from 'styled-components';

import { COLORS } from '../../constants.styles';

export const CardBody = styled.div`
  margin: 20px;
  background-color: white;
  color: black;
  box-shadow: 0px 10px 20px 2px #e2e2e2;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  max-width: 525px;
  min-height: 250px;
  height: calc(100% - 20px);
  padding: 30px 40px 0px 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  :-webkit-any-link {
    text-decoration: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;

  h3 {
    font-weight: 400;
    line-height: 30px;
  }
  p {
    color: ${COLORS.DARK_GREY};
    margin: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SubTextContainer = styled.p`
  text-align: justify;
`;

export const Image = styled.img`
  margin-left: 15px;
  max-width: none;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;
