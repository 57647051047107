import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Page from '../layout/Page';
import Section from '../components/common/Section';
import PageLinkGrid from '../components/PageLinkGrid/PageLinkGrid';

import { ProductGridWrapper } from '../components/index.styled';
import { COLORS } from '../constants.styles';

import NewsCarousel from '../components/common/NewsCarousel';
import Header from '../components/common/Header';

const newsSorter = (a, b) => {
  if (new Date(a.node.last_publication_date) > new Date(b.node.last_publication_date)) {
    return -1;
  }
  return 1;
};

const IndexPage = ({ data }) => {
  const heroBanner = get(data, 'prismicHomePage.data.hero_banner.document.data');

  const productCards = [
    get(data, 'prismicHomePage.data.top_left_card.document.data'),
    get(data, 'prismicHomePage.data.top_right_card.document.data'),
    get(data, 'prismicHomePage.data.bottom_left_card.document.data'),
    get(data, 'prismicHomePage.data.bottom_right_card.document.data'),
  ];

  const newsSlides = get(data, 'allPrismicNewsSlide.edges', [])
    .sort(newsSorter)
    .map(newsSlide => ({ ...newsSlide.node.data, id: newsSlide.node.id }));

  return (
    <Page heroBanner={heroBanner}>
      <ProductGridWrapper>
        <PageLinkGrid productCards={productCards} />
      </ProductGridWrapper>
      <Section theme={COLORS.LIGHT_GREY}>
        <div className="container">
          <Header>Actualités</Header>
          <NewsCarousel news={newsSlides} />
        </div>
      </Section>
    </Page>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismicHomePage: PropTypes.shape({}),
    allPrismicNewsSlide: PropTypes.shape({}),
    allPrismicProductCard: PropTypes.shape({}),
  }),
};

export default IndexPage;

export const query = graphql`
  query {
    prismicProspectivePage {
      data {
        downloads {
          document {
            ... on PrismicDownloadColumn {
              data {
                body {
                  ... on PrismicDownloadColumnBodyDownloadable {
                    primary {
                      description {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicHomePage {
      data {
        hero_banner {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicHeroBanner {
                data {
                  title {
                    html
                    text
                  }
                  description {
                    html
                    text
                  }
                  link {
                    text
                  }
                  link_text {
                    text
                  }
                }
              }
            }
          }
        }
        top_left_card {
          id
          ...linkType
        }
        top_right_card {
          id
          ...linkType
        }
        bottom_left_card {
          id
          ...linkType
        }
        bottom_right_card {
          id
          ...linkType
        }
      }
    }

    allPrismicNewsSlide {
      edges {
        node {
          id
          last_publication_date
          data {
            image {
              url
            }
            title {
              text
            }
            description {
              html
            }
            article_url {
              url
            }
          }
        }
      }
    }
  }
  fragment linkType on PrismicLinkType {
    document {
      ... on PrismicProductCard {
        data {
          title {
            text
          }
          paragraph {
            text
          }
          image {
            url
            dimensions {
              width
              height
            }
          }
          link {
            text
          }
        }
      }
      ... on PrismicHeroBanner {
        data {
          title {
            html
            text
          }
          description {
            html
            text
          }
          link {
            text
          }
          link_text {
            text
          }
        }
      }
    }
  }
`;
