import React from 'react';
import PropTypes from 'prop-types';

import { Column, TextContainer, Image, SlideHeading, SlideDescription } from './NewsSlide.styled';

const NewsSlide = ({ header, descriptionHTML, link, image, active }) => (
  <div className={`carousel-item ${active ? 'active' : ''}`}>
    <div className="row">
      <Column className="col-md-4">
        <Image url={image} />
      </Column>
      <Column className="col-md-8">
        <TextContainer>
          <SlideHeading>{header}</SlideHeading>
          <SlideDescription dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
          <a href={link} target="_blank" rel="noopener noreferrer">
            Continuer de lire
          </a>
        </TextContainer>
      </Column>
    </div>
  </div>
);

NewsSlide.propTypes = {
  header: PropTypes.string.isRequired,
  descriptionHTML: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default NewsSlide;
